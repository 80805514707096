/**
 * single-use-limit.js
 */

(function ($) {
    $(document).ready(function () {
        if ($('#single-use-limit-result').length) {
            $('#gender').on("click", "button", function () {
                $("#gender button").removeClass("active");
                $(this).toggleClass("active");
                weight.removeAttribute('disabled');
                $('#gender-select').removeClass('active');
                calculateSingleUseLimit()
            });
            // Update weight output
            const weight = document.getElementById('weight');
            weight.addEventListener('input', function () {
                calculateSingleUseLimit()
            });

            function calculateSingleUseLimit() {
                let bodyWeightKg = parseFloat($('#weight').val());
                const genderMultiplier = parseFloat($("#gender button.active").data('multiplier'));
                const alcoholLimitGrams = bodyWeightKg * genderMultiplier; // to stay under 1 promille
                const standardDrinkGrams = 12;
                const singleUseLimit = alcoholLimitGrams / standardDrinkGrams;
                $('#single-use-limit-result #single-use-limit').text(singleUseLimit.toFixed(1));
            }
        }
    });


})(jQuery);
